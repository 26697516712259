.navbar {
    background: #fff;
    width: calc(100% - 270px);
    margin-left: auto;
    position: fixed;
    top: 0;
    right: 0;
    padding: 0 15px;
    z-index: 1;
    &.minimize {
        width: calc(100% - 60px);
    }
    form {
        position: relative;
        input[type="text"] {
            width: 100%;
            @media screen and (min-width: 991px) {
                width: 320px;
            }
        }
        svg {
            position: absolute;
            right: 25px;
            top: 13px;
        }
    }
    .profile-wrapper {
        align-items: center;
        gap: 15px;
        .notifications {
            cursor: pointer;
            span {
                position: absolute;
                top: -8px;
                right: -8px;
                background: #EC3040;
                width: 16px;
                height: 16px;
                border-radius: 25px;
                color: #fff;
                text-align: center;
                padding: 2px;
                font-size: 10px;
            }
        }
        .user-profile {
            display: flex;
            align-items: center;
            img {
                width: 50px;
                border-radius: 50%;
                border: 1px solid #ec3040;
                padding: 2px;
                z-index: 2;
                position: relative;
            }
            .dropdown-toggle {
                background: #f2f3f9;
                padding: 5px 10px 5px 20px;
                border-radius: 25px;
                margin-left: -15px;
                z-index: 1;
                font-size: 14px;
                color: #0f3059;
                display: flex;
                align-items: center;
            }
            .dropdown-menu[data-bs-popper] {
                right: 25px;
                left: auto;
                padding: 0;
                a {
                    padding: 6px 10px;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        & {
            width: calc(100% - 60px);
            .container-fluid {
                gap: 10px;
            }
        }
    }
}
.sidebar {
    width: 270px;
    background: #0f3059;
    box-shadow: 3px 0px 10px #0f30590d;
    min-height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    .small {
        display: none;
    }
    &.minimize {
        width: 60px;
        .small {
            display: block;
            width: 40px;
        }
        .big {
            display: none;
        }
        .sidebar-wrapper {
            align-items: center;
            padding: 18px;
            & .drp-toggle {
                display: block;
                width: 60px;
                & .panel-collapse {
                    display: none;
                }
            }
            .dropdown-btn {
                .tab-text,
                i {
                    display: none;
                }
                &.active::before {
                    width: 60px !important;
                    left: -20px !important;
                    top: -2px !important;
                }
            }

            .nav-link {
                svg {
                    margin-right: 0 !important;
                }
                span.tab-text,
                .icon,
                .badge {
                    display: none;
                }
                &.active::before,
                &[aria-expanded="true"]::before {
                    width: 60px !important;
                    left: -20px !important;
                    top: -2px !important;
                }
                &.active.show::before {
                    width: 60px !important;
                    left: -20px !important;
                }
            }
            // .nav-item.dropdown {
            //     & {
            //         .nav-link.dropdown-toggle.show::before {
            //             width: 60px !important;
            //             left: -20px !important;
            //         }
            //     }
            // }
        }
    }
    .sidebar-wrapper {
        display: flex;
        gap: 20px;
        padding: 20px;
        .nav {
            flex-direction: column;
            width: 100%;
            flex-shrink: 0;
            gap: 20px;
            .nav-item {
                display: flex;
                align-items: center;
                svg {
                    z-index: 2;
                    fill: #858dac;
                    margin-right: 10px;
                }
                & {
                    display: block;
                    .dropdown-btn {
                        padding: 8px 0;
                        text-decoration: none;
                        color: #858dac;
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 400;
                        display: block;
                        border: none;
                        background: none;
                        width: 100%;
                        text-align: left;
                        cursor: pointer;
                        outline: none;
                        position: relative;
                        i {
                            position: absolute;
                            right: 30px;
                            top: 30%;
                        }
                        &.active {
                            color: #fff;
                            font-weight: 500;
                            background: transparent;
                            position: relative;
                            z-index: 1;
                            svg {
                                fill: #fff;
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                left: -50px;
                                top: 0;
                                background: linear-gradient(109deg, #ec3040 0%, #f16a75 100%);
                                box-shadow: 3px 5px 10px #0000001a;
                                border-radius: 0px 20px 20px 0px;
                                width: 270px;
                                height: 40px;
                                z-index: -1;
                            }
                        }
                    }
                    .dropdown-container {
                        display: none;
                        background-color: transparent;
                        padding-left: 15px;
                        a {
                            display: block;
                            color: #ffffffa6;
                            font-size: 13px;
                            padding: 10px 20px;
                            &:hover {
                                background: linear-gradient(109deg, #ec3040 0%, #f16a75 100%);
                                border-radius: 25px;
                            }
                        }
                    }
                }
                &.drp-toggle {
                    .panel {
                        width: 100%;
                        .nav-link {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }
                    & .panel-collapse {
                        .list-group-item {
                            background: transparent;
                            border: 0;
                            padding: 0;
                            a {
                                display: block;
                                color: #ffffffa6;
                                font-size: 13px;
                                padding: 10px 30px;
                                // &:hover {
                                //     background: linear-gradient(109deg, #ec3040 0%, #f16a75 100%);
                                //     border-radius: 25px;
                                // }
                            }
                            span {
                                color: #fff;
                            }
                        }
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    }
                }
                // &.dropdown {
                //     .nav-link.dropdown-toggle {
                //         color: #858dac;
                //         position: relative;
                //         svg {
                //             fill: #858dac;
                //         }
                //         &.show {
                //             color: #fff;
                //             svg {
                //                 fill: #fff;
                //             }
                //         }
                //         &.show::before {
                //             content: "";
                //             position: absolute;
                //             left: -50px;
                //             top: 0;
                //             background: linear-gradient(109deg, #ec3040 0%, #f16a75 100%);
                //             box-shadow: 3px 5px 10px #0000001a;
                //             border-radius: 0px 20px 20px 0px;
                //             width: 255px;
                //             height: 40px;
                //             z-index: -1;
                //         }
                //     }
                //     @media screen and (max-width: 600px) {
                //         & {
                //             .nav-link.dropdown-toggle.show::before {
                //                 width: 60px !important;
                //                 left: -20px !important;
                //             }
                //         }
                //     }
                // }
                .nav-link {
                    color: #858dac;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 400;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    padding: 8px 0;
                    position: relative;
                    &::after {
                        display: none;
                    }
                    & .badge {
                        background: #fe9000;
                        border-radius: 50%;
                        position: absolute;
                        right: 30px;
                    }
                    .icon {
                        position: absolute;
                        right: 30px;
                        fill: none;
                    }
                    &[aria-expanded="true"] {
                        color: #fff;
                        svg {
                            fill: #fff;
                        }
                        .icon {
                            fill: none;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            left: -50px;
                            top: 0;
                            background: linear-gradient(109deg, #ec3040 0%, #f16a75 100%);
                            box-shadow: 3px 5px 10px #0000001a;
                            border-radius: 0px 20px 20px 0px;
                            width: 270px;
                            height: 40px;
                            z-index: -1;
                        }
                    }
                    &.active {
                        color: #fff;
                        font-weight: 500;
                        background: transparent;
                        position: relative;
                        z-index: 1;
                        svg {
                            fill: #fff;
                        }
                        & .badge {
                            display: none;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            left: -50px;
                            top: 0;
                            background: linear-gradient(109deg, #ec3040 0%, #f16a75 100%);
                            box-shadow: 3px 5px 10px #0000001a;
                            border-radius: 0px 20px 20px 0px;
                            width: 270px;
                            height: 40px;
                            z-index: -1;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        & {
            width: 60px;
            .small {
                display: block;
                width: 40px;
            }
            .big {
                display: none;
            }
            .sidebar-wrapper {
                .nav-link {
                    span.tab-text,
                    .icon,
                    .badge {
                        display: none;
                    }
                    &.active::before,
                    &[aria-expanded="true"]::before {
                        width: 60px !important;
                        left: -20px !important;
                    }
                }
            }
        }
    }
}
.log-out{
    text-align: center;
}