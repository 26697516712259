input[type="text"],
input[type="password"] {
    padding: 10px 20px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    border: 1px solid #dce9f9;
    border-radius: 21px;
    width: 100%;
    &::placeholder {
        color: #858dac;
    }
    &:focus {
        box-shadow: none;
        outline: 0;
    }
}
