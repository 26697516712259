.defaultHading {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #0F3059;
    margin-bottom: 0;
    &.h2 {
        font-size: 24px;
        line-height: 54px;
        text-transform: capitalize;
    }
}