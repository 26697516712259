.dashboard-wrapper {
    &.users-page {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .breadcrumb {
            margin-bottom: 0;
        }
        .btns-wrapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px;
            .searchbox {
                position: relative;
                svg {
                    position: absolute;
                    right: 15px;
                    top: 12px;
                }
                input[type="text"] {
                    width: 320px;
                }
            }
            .filter {
                border: 1px solid #a8c8f0;
                border-radius: 20px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
            }
            .share-btn {
                position: relative;
                svg {
                    position: absolute;
                    right: 10px;
                    top: 9px;
                }
            }
        }
        @media screen and (max-width: 991px) {
            & {
                flex-wrap: wrap;
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
                .btns-wrapper {
                    input[type="text"] {
                        width: 100%;
                    }
                }
            }
        }
    }
}
.modal {
    .modal-body {
        padding: 0;
        .modal-head {
            background: #ec30400d;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;
            overflow: hidden;
            img {
                height: 140px;
                transform: translateY(10px);
                &.security {
                    transform: none;
                    height: auto;
                }
                &.export-img {
                    height: 125px;
                    transform: translateY(8px);
                }
            }
        }
        .content {
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            p {
                font-size: 16px;
                color: #0f3059;
                font-weight: 400;
                &.alertText {
                    font-weight: 600;
                }
                &.sm-bold {
                    font-weight: 500;
                }
            }
            .btns {
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 15px;
            }
            form {
                input {
                    margin-bottom: 10px;
                    width: calc(100% - 30px);
                }
            }
        }
    }
}
.table-wrapper {
    background: #ffffff;
    box-shadow: 0px 3px 7px #0f30590d;
    border-radius: 3px;
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 50px;
    .nav-pills {
        .nav-item {
            width: 33.3%;
            text-align: center;
            &:first-child {
                .nav-link {
                    border-radius: 5px 0px 0px 5px;
                    border-right: 0;
                }
            }
            &:last-child {
                .nav-link {
                    border-radius: 0px 5px 5px 0px;
                    border-left: 0;
                }
            }
            .nav-link {
                box-shadow: 3px 5px 10px #2d74cc33;
                border: 1px solid #2474d5;
                border-radius: 0;
                font-size: 14px;
                padding: 12px;
                font-weight: 400;
                color: #2268c0;
                &.active {
                    background: linear-gradient(99deg, #3f87de 0%, #2268c0 100%);
                    color: #fff;
                }
            }
        }
        @media screen and (max-width: 600px) {
            & {
                flex-direction: column;
                .nav-item {
                    width: 100%;
                }
            }
        }
    }
    .tab-content {
        margin: 30px 0 20px 0;
        .top-btns {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            justify-content: flex-end;
            border: 1px solid #e9e9e9;
            padding: 12px;
            display: flex;
        }
        table {
            border: 1px solid #e9e9e9;
            margin-bottom: 30px;
            & > :not(:first-child) {
                border-top: none;
            }
            --bs-table-striped-bg: #fafafa;

            tr {
                border: 1px solid #e9e9e9;
                th,
                td {
                    font-size: 14px;
                    color: #0f3059;
                    font-weight: 400;
                    line-height: 42px;
                    white-space: nowrap;
                    input[type="checkbox"] {
                        margin: 1em;
                        cursor: pointer;
                        &:focus {
                            box-shadow: none;
                        }
                    }
                    &.email {
                        a {
                            color: #2268c0;
                        }
                    }
                }
                th {
                    font-weight: 600;
                }
            }
        }
        
    }
}
