.dashboard-wrapper {
    .breadcrumb {
        margin-bottom: 20px;
        align-items: center;
        li {
            a {
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                color: #858dac;
                cursor: pointer;
            }
            &:last-child {
                span {
                    color: #ec3040;
                }
            }
        }
        a {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            color: #858dac;
            cursor: pointer;
            :first-child {
                color: #a8c8f0;
                padding: 0 6px;
            }
            :last-child {
                color: #ec3040;
            }
        }
    }
    .cards-wrapper {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
        .top-card {
            width: 25%;
            h5 {
                font-size: 14px;
                font-weight: 400;
                color: #0f3059;
                line-height: 24px;
            }
            .card-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .count {
                    font-size: 24px;
                    line-height: inherit;
                    font-weight: 600;
                    color: #0f3059;
                }
                .card-img {
                    background: transparent linear-gradient(155deg, #ec3040 0%, #f16a75 100%);
                    box-shadow: 3px 5px 10px #ec304033;
                    padding: 10px;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            p {
                font-size: 12px;
                font-weight: 400;
                color: #858dac;
                line-height: 18px;
                display: flex;
                align-items: center;
                gap: 4px;
                span {
                    color: #ec3040;
                }
                svg {
                    &.first {
                        circle,
                        polyline,
                        line {
                            color: #ec3040;
                        }
                    }
                }
            }
            &:nth-child(2) {
                .card-content {
                    .card-img {
                        background: transparent linear-gradient(144deg, #3f87de 0%, #2268c0 100%);
                        box-shadow: 3px 5px 10px #2d74cc33;
                        border: 1px solid #ffffff;
                    }
                }
                p {
                    span,
                    svg.second {
                        color: #3a82d9;
                        circle,
                        polyline,
                        line {
                            color: #3a82d9;
                        }
                    }
                }
            }
            &:nth-child(3) {
                .card-content {
                    .card-img {
                        background: transparent linear-gradient(155deg, #a46cec 0%, #ea60b0 100%);
                        box-shadow: 3px 5px 10px #ea60b04d;
                    }
                }
                p {
                    span,
                    svg.third {
                        color: #c067d4;
                        circle,
                        polyline,
                        line {
                            color: #c067d4;
                        }
                    }
                }
            }
            &:nth-child(4) {
                .card-content {
                    .card-img {
                        background: transparent linear-gradient(155deg, #3fd8d4 0%, #1daad2 100%);
                        box-shadow: 3px 5px 10px #1daad24d;
                    }
                }
                p {
                    span,
                    svg.fourth {
                        color: #29bad2;
                        circle,
                        polyline,
                        line {
                            color: #29bad2;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1024px) {
            & {
                flex-wrap: wrap;
                .top-card {
                    width: 48%;
                }
            }
        }
        @media screen and (max-width: 991px) {
            & {
                .top-card {
                    width: 98%;
                }
            }
        }
    }
    .card-chart-wrapper {
        overflow-x: hidden;
        margin-bottom: 50px;
        .Doughnut-card {
            padding: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .card-head {
                border-bottom: 1px solid #e9eaf0;
                padding: 20px;
                h4 {
                    font-size: 16px;
                    font-weight: 500;
                    color: #0f3059;
                }
            }
            .card-content {
                padding: 20px;
                position: relative;
                width: 90%;
                margin: auto;
                overflow: hidden;
                .left {
                    position: absolute;
                    left: 0;
                    top: 35%;
                    p {
                        font-size: 13px;
                        line-height: 18px;
                        color: #0f3059;
                        font-weight: 400;
                        &:last-child {
                            color: #858dac;
                        }
                    }
                    .dropdown-divider {
                        width: 120px;
                    }
                }
                .right {
                    position: absolute;
                    right: 0;
                    top: 35%;
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    p {
                        font-size: 13px;
                        line-height: 18px;
                        color: #0f3059;
                        font-weight: 400;
                        &:last-child {
                            color: #858dac;
                        }
                    }
                    .dropdown-divider {
                        width: 120px;
                    }
                }
                .indicator {
                    text-align: center;
                    font-size: 13px;
                    line-height: 18px;
                    color: #858dac;
                    font-weight: 400;
                    font-style: italic;
                }
                .vertical-indicator-text {
                    position: absolute;
                    left: -22px;
                    top: 35%;
                    transform: rotate(271deg);
                    font-size: 13px;
                    color: #858dac;
                    font-style: italic;
                }
                canvas {
                    padding: 25px;
                }
                .aboutChart {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    text-align: center;
                    p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 30px;
                        &:first-child {
                            color: #0f3059;
                            position: relative;
                            padding-left: 15px;
                            display:inline-block;
                            &::before {
                                content: "";
                                position: absolute;
                                top: 10px;
                                left: 0;
                                background-color: #3a82d9;
                                height: 10px;
                                width: 10px;
                                border-radius: 10px;
                            }
                            &.other {
                                &::before {
                                    background-color: #ec3040;
                                }
                            }
                        }
                        &:last-child {
                            color: #858dac;
                        }
                    }

                    h4 {
                        font-size: 24px;
                        line-height: 34px;
                        font-weight: 600;
                        color: #0f3059;
                    }
                }
            }
        }
        @media screen and (max-width: 1024px) {
            & {
                .row {
                    flex-wrap: wrap;
                    gap: 20px;
                    & > div {
                        width: 98%;
                    }
                }
            }
        }
    }
}
