.subscription-content-wrapper {
    background: #ffffff;
    box-shadow: 0px 3px 7px #0f30590d;
    border-radius: 3px;
    padding: 30px;
    margin: 20px 0 50px 0;
    table {
        border: 1px solid #e9e9e9;
        margin-bottom: 30px;
        & > :not(:first-child) {
            border-top: none;
        }
        --bs-table-striped-bg: #fafafa;

        tr {
            border: 1px solid #e9e9e9;
            th,
            td {
                font-size: 14px;
                color: #0f3059 !important;
                font-weight: 400;
                line-height: 35px;
                padding: 12px 20px;
                white-space: nowrap;
                input[type="checkbox"] {
                    margin: 1em;
                    cursor: pointer;
                    &:focus {
                        box-shadow: none;
                    }
                }
                &.email {
                    a {
                        color: #2268c0;
                    }
                }
            }
            th {
                font-weight: 600;
            }
        }
    }
}
