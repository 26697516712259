.btn {
    background-color: #000;
    color: #fff;
    padding: 10px 25px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    border: 0;
    cursor: pointer;
    &:focus {
        outline: 0;
        box-shadow: none;
    }
    &.btn-outline {
        background-color: #fff;
        color: #000;
        border: 1px solid #000;
    }
    &.icon-btn {
        border-radius: 25px;
        background: linear-gradient(120deg, #EC3040 0%, #F16A75 100%);
        padding: 10px 34px 10px 25px;
        &:hover {
            color: #fff;
        }
    }
    &.transparent-btn {
        background: transparent;
        border: 1px solid #2268C0;
        color: #2268C0;
        padding: 10px 25px;
        border-radius: 25px;
    }
    &.transparent-btn1 {
        background: transparent;
        border: 1px solid #EC3040;
        color: #EC3040;
        padding: 10px 25px;
        border-radius: 25px;
    }
}

