.user-profile-content {
    background: #fff;
    box-shadow: 0px 3px 7px #0f30590d;
    border-radius: 3px;
    .profile-bg {
        width: 100%;
        height: 200px;
    }
    .user-profile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 20px;
        .user-img-wrapper {
            display: flex;
            align-items: center;
            gap: 30px;
            img {
                margin-top: -55px;
                border-radius: 50%;
                border: 1px solid #ec3040;
                padding: 3px;
                background: #fff;
            }
            .user-name {
                font-size: 24px;
                font-weight: 600;
                color: #0f3059;
            }
        }
        .user-btns {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
        }
        @media screen and (max-width: 767px) {
            & {
                gap: 15px;
            }
        }
    }

    .user-data {
        margin: 35px 0;
        padding: 20px 20px 50px 20px;
        .user-profile-table {
            border: 1px solid #e9e9e9;
            & > :not(:first-child) {
                border-top: 1px solid #e9e9e9;
            }
            thead {
                td {
                    padding: 12px;
                    white-space: nowrap;
                    h3 {
                        color: #0f3059;
                        font-size: 16px;
                        font-weight: 400;
                    }
                    &:nth-child(1) {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        svg {
                            margin: 5px;
                        }
                    }
                    &:last-child {
                        text-align: right;
                        color: #0f3059;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
            tbody {
                & tr {
                    border-bottom: 1px solid #e9e9e9;
                    td {
                        padding: 12px;
                        white-space: nowrap;
                        svg {
                            margin: 5px;
                        }
                        h3 {
                            color: #0f3059;
                            font-size: 16px;
                            font-weight: 400;
                        }
                        &:nth-child(1) {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            svg {
                                margin: 5px;
                            }
                        }
                        &:last-child {
                            text-align: right;
                            color: #0f3059;
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                    &:nth-of-type(odd) {
                        background: #fafafa;
                    }
                }
            }
        }
    }
    .modal {
        .modal-body {
            padding: 0;
            .content {
                // padding: 30px;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                // flex-direction: column;
                // text-align: center;
                // p {
                //     font-size: 16px;
                //     color: #0F3059;
                //     font-weight: 400;
                // }
                // .btns {
                //     margin-top: 20px;
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
                //     gap: 15px;
                // }
                form {
                    input {
                        margin-bottom: 10px;
                        width: calc(100% - 30px);
                    }
                }
            }
        }
    }
}
.profile_image{
    width: 120px;
}