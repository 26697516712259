@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;600;700&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Ubuntu", sans-serif;
}
strong {
    font-weight: bold;
}

ol,
ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}
a:hover,
a,
a:focus,
a:hover {
    text-decoration: none;
    outline: none;
}

img {
    display: block;
    max-width: 100%;
}
.App {
    display: flex;
    gap: 20px;
    background-color: #f5f5f7;
}
.main-content-wrapper {
    margin-top: 85px;
    padding: 10px 15px 10px 10px;
    flex: 1;
    margin-left: 280px;
    overflow-x: hidden;
    min-height: 100vh;
    &.minimize {
        margin-left: 70px;
    }
    @media screen and (max-width: 767px) {
        & {
            margin-top: 110px;
        }
    }
    @media screen and (max-width: 600px) {
        & {
            margin-left: 70px;
            margin-top: 85px;
        }
    }
    @media screen and (max-width: 569px) {
        & {
            margin-top: 120px;
        }
    }
    @media screen and (max-width: 400px) {
        & {
            margin-top: 150px;
        }
    }
}
.defaultCard {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 3px 7px #0f30590d;
    border-radius: 3px;
    min-height: 120px;
}
.toast-position {
    position: fixed;
    top: 20px;
    right: 0;
    z-index: 99;
    .toast {
        background: #2fc129;
        box-shadow: 3px 5px 10px #2fc12d4d;
        border-radius: 5px;
        color: #fff;
        width: 100%;
        .toast-body {
            font-size: 14px;
            font-weight: 400;
            i {
                font-size: 18px;
            }
        }
        .btn-close {
            opacity: 1;
            padding: 0 10px;
            font-size: 12px;
            &:focus {
                box-shadow: none;
            }
        }
    }
}
.searchbox {
    position: relative;
    svg {
        position: absolute;
        right: 15px;
        top: 12px;
    }
}
.RichTextEditor__root___2QXK- {
    .EditorToolbar__root___3_Aqz {
        margin: 0;
        padding: 10px 15px;
        .ButtonGroup__root___3lEAn {
            margin: 0;
            button {
                background: transparent;
                border: 0;
                margin-right: 5px;
                &.IconButton__isActive___2Ey8p {
                    background: #d8d8d8;
                }
            }
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                display: none;
            }
        }
    }
    .RichTextEditor__editor___1QqIU {
        // min-height: 120px;
        font-family: "Ubuntu", sans-serif;
        font-size: 14px;
        .public-DraftEditorPlaceholder-inner {
            font-size: 12px;
            color: #858dac;
            font-weight: 400;
            font-style: italic;
            font-family: "Ubuntu", sans-serif;
        }
        div[role="textbox"] {
            min-height: 120px;
        }
    }
}
.pagination {
    flex-wrap: wrap;
    .page-link {
        color: #2268c0;
        font-size: 12px;
        font-weight: 400;
        &:focus {
            box-shadow: none;
        }
    }
}
.ReactTable {
    .rt-thead {
        box-shadow: none;
        padding: 10px 0;
        border-bottom: 1px solid #e9e9e9;
        .rt-th {
            border-right: 0 !important;
            box-shadow: none !important;
            padding: 0 !important;
            text-align: center;
            .rt-resizable-header-content {
                font-size: 14px;
                color: #0f3059;
                font-weight: 600;
                line-height: 42px;
                white-space: nowrap;
                input[type="checkbox"] {
                    margin: 1em;
                    cursor: pointer;
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
            // &:first-child,
            // &:nth-child(2){
            //     width: 40px !important;
            //     text-align: center;
            // }
            // &:nth-child(5),
            // &:nth-child(6) {
            //     width: 50px !important;
            //     text-align: center;
            // }
            // &:nth-child(4) {
            //     width: 140px !important;
            // }
            // &:nth-child(7) {
            //     width: 110px !important;
            //     text-align: center;
            // }
            // &:last-child {
            //     text-align: center;
            // }
        }
    }
    .rt-tbody {
        .rt-tr-group {
            border-bottom: 1px solid #e9e9e9 !important;
            .rt-tr {
                &.-odd {
                    background: #FAFAFA
                }
            }
            .rt-td {
                border-right: 0;
                padding: 10px 0;
                text-align: center;
                font-size: 14px;
                color: #0f3059;
                font-weight: 400;
                line-height: 42px;
                white-space: nowrap;
                input[type="checkbox"] {
                    margin: 1em;
                    cursor: pointer;
                    &:focus {
                        box-shadow: none;
                    }
                }
                // &:first-child,
                // &:nth-child(2){
                //     width: 40px !important;
                //     text-align: center;
                // }
               
                // &:nth-child(5),
                // &:nth-child(6) {
                //     width: 50px !important;
                //     text-align: center;
                // }
                // &:nth-child(4) {
                //     color: #2268C0;
                // }
                // &:nth-child(7) {
                //     text-align: center;
                // }
                // &:last-child {
                //     text-align: center;
                // }
            }
        }
    }
    .-pagination {
        border-top: 0 !important;
        box-shadow: none !important;
        padding: 10px !important;

        button {
            font-size: 12px !important;
            font-weight: 400 !important;
            color: #2268C0 !important;
            background: transparent !important;
            &:hover {
                background: #2268C0 !important;
                color: #fff !important;
            }
        }
        .-pageInfo,
        select {
            font-size: 12px !important;
            font-weight: 400 !important;
            color: #2268C0;
        }
    }
}