.email-page-content {
    margin-top: 20px;
    input[type="checkbox"] {
        cursor: pointer;
    }
    .contact-list-wrapper,
    .compose-mail-wrapper {
        background: #ffffff;
        box-shadow: 0px 3px 7px #0f30590d;
        border-radius: 3px;
        height: 100%;
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 25px;
            border-bottom: 1px solid #e9eaf0;
            .icon {
                color: #858dac;
                font-size: 20px;
                cursor: pointer;
            }
            h3 {
                color: #0f3059;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
    .contact-list {
        padding: 20px;
        .searchbox {
            padding-bottom: 15px;
        }
        .contacts {
            max-height: 455px;
            overflow-y: auto;
            .contact {
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                .contact-img {
                    align-items: center;
                    gap: 10px;
                    img {
                        width: 50px;
                        border: 1px solid #ec3040;
                        padding: 2px;
                        border-radius: 50%;
                    }
                    h4 {
                        color: #0f3059;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .form-wrapper {
        padding: 25px;
        form {
            input[type="email"],
            input[type="text"],
            textarea {
                padding: 8px 14px;
                font-size: 14px;
                line-height: 26px;
                font-weight: 400;
                border: 1px solid #e9e9e9;
                border-radius: 3px;
                width: 100%;
                &::placeholder {
                    color: #858dac;
                    font-style: italic;
                }
                &:focus {
                    box-shadow: none;
                    outline: 0;
                }
            }
            textarea {
                height: 150px;
            }
            & > div {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                label {
                    width: 75px;
                    color: #0f3059;
                    font-size: 14px;
                    font-weight: 400;
                    flex-shrink: 0;
                }
                @media screen and (max-width: 420px) {
                    & {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                    }
                }
            }
            .preview-images {
                margin-bottom: 0;
                margin-top: 25px;
                figure {
                    img {
                        width: 60px;
                        height: 60px;
                    }
                    .close-icon {
                        position: absolute;
                        top: -8px;
                        right: -8px;
                        background: #ec3040;
                        width: 22px;
                        height: 22px;
                        border-radius: 25px;
                        color: #fff;
                        text-align: center;
                        padding: 2px;
                        cursor: pointer;
                    }
                }
            }
            .file-upload {
                justify-content: space-between;
                padding-top: 15px;
                .send {
                    padding: 10px 25px;
                }
                .upload-btn-wrapper {
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    .uploadbtn {
                        background-color: white;
                        border-radius: 8px;
                        font-size: 12px;
                        color: #2268c0;
                        font-weight: 400;
                        padding: 0;
                    }
                }
            }
        }
    }
    .modal {
        .modal-body {
            padding: 30px;
        }
        .modal-head {
            background: #fff;
            height: auto;
            padding-top: 0;
            h4 {
                font-size: 20px;
                color: #0f3059;
                font-weight: 600;
            }
        }
        .upload-content {
            background: #ec30400d;
            border: 1px dashed #ec3040;
            border-radius: 3px 3px 0px 0px;
            min-height: 300px;
            margin: 30px 0;
            .drag-area {
                .icon {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    position: relative;
                    padding-top: 30px;
                    svg {
                        &:nth-child(2) {
                            transform: translateY(-40px);
                        }
                    }
                }
                .drag-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 15px;
                }
                header {
                    font-size: 16px;
                    font-weight: 500;
                    color: #0f3059;
                    span {
                        font-size: 16px;
                        font-weight: 400;
                        color: #0f3059;
                    }
                }
                img {
                    height: 100px;
                    width: 100px;
                    object-fit: contain;
                    display: inline-block;
                    margin: 10px;
                    border-radius: 5px;
                }
            }
        }
        .btns {
            display: flex;
            justify-content: flex-end;
            gap: 15px;
            & .icon-btn {
                padding: 10px 25px;
            }
        }
    }
}
.error {
    margin-top: 6px;
    font-size: 12px;
    font-weight: 400;
    color: tomato;
}
.position-relative{
    margin: 10px !important;
}
.emailCheckbox{
    margin-right: 15px;
}
