.login-wapper{   
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .left-part{
        width: 30%;
        background-color: black;
        background-image: url(../../assets/login-Sidebar.png);
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        .logo-elm{
            margin: 35px 0 0 35px;
        }
        .left-pt-btom{
            padding: 0 50px 70px;
            width: 100%;
            h2{
                color: #ee4543;
                font-size: 28px;
                font-weight: 600;
                span{
                    font-weight: 400;
                    color: #fff;
                }
            }
            p{
                color: #fff;
                opacity: 0.8;
                margin-top: 10px;
                letter-spacing: 0.12px;
                font-size: 12px;
            }
        }
    }
    .right-part{
        width: 70%;
        background-color: #F2F2F7;
        .login-form {
            width: 90%;
            max-width: 500px;
            h3{
                color: #181824;
                font-size: 2rem;
                margin-bottom: 10px;
                font-weight: bold;
            }
            input{
                margin-top: 15px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #E6E6E6;
                border-radius: 5px;
                // padding-left: 41px;
                background-size: 14px;
                background-position: left 15px center;
                height: 44px;
                &::placeholder{
                    font-size: 12px;
                    color: #000000;
                    opacity: 0.5;
                    font-weight: normal;
                }
                &.user-id{
                    background-image: url("../../assets/user.png");
                }
                &.password-id{
                    background-image: url("../../assets/key.png");
                }
            }
            .forgot-pass{
                margin-top: 15px;
                font-size: 14px;
                font-weight: 500;
                color: #000;
                cursor: pointer;
            }
            .login-btn{
                    height: 44px;
                    background: #ee4543 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    border: none;
                    margin-top: 25px;
                    color: #fff;
                    font-weight: bold;
                    font-size: 14px;
                    &:hover{
                        box-shadow: 0px 5px 15px #B46B5329;
                    }
            }
        }
    }
}
.rememberMe-check{
    display:inline-block;
    height: 15px !important ;
    margin-right: 10px;
    cursor: pointer;
}
.forgot-pass:hover {
    color: red !important;
  }