.chip-wrapper {
    display: flex;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    width: 100%;
    align-items: center;
    padding: 10px 8px;
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    .tag-item {
        background-color: #a8c8f0;
        display: inline-block;
        font-size: 12px;
        color: #0f3059;
        font-weight: 400;
        border-radius: 30px;
        padding: 8px 14px;
        display: inline-flex;
        align-items: center;
        margin: 0 0.3rem 0 0;
        & > .button {
            background-color: transparent;
            color: #2268c0;
            border: none;
            cursor: pointer;
            font: inherit;
            margin-left: 5px;
            font-weight: 600;
            padding: 0;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            &:focus {
                box-shadow: none;
                outline: 0;
            }
        }
    }
    .tag-wrapper {
        width: 100%;
        .input {
            // padding: 10px;
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            border: 0;
            border-radius: 3px;
            width: 100%;
            &::placeholder {
                color: #858dac;
                font-style: italic;
            }
            &:focus {
                box-shadow: none;
                outline: 0;
            }
            &.has-error {
                border-color: tomato;
            }
        }
        .error {
            margin-top: 6px;
            font-size: 14px;
            font-weight: 400;
            color: tomato;
        }
    }
}
